import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./index@gen.scss";

const GeneratedUseCasesServiceOwnershipIndex = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          PagerDuty活用例：サービスオーナーシップ｜PagerDuty正規販売代理店（株）Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDutyは、継続的な改善と革新を推進するために、サービス全体でチームに明確な責任を与えます。このサービスオーナーシップにより、問題が発生した場合に適切なレスポンダーを毎回迅速に引き込むことで、ダウンタイムと顧客への影響を最小限に抑えられます。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDutyは、継続的な改善と革新を推進するために、サービス全体でチームに明確な責任を与えます。このサービスオーナーシップにより、問題が発生した場合に適切なレスポンダーを毎回迅速に引き込むことで、ダウンタイムと顧客への影響を最小限に抑えられます。"
        />

        <meta
          property="og:title"
          content="PagerDuty活用例：サービスオーナーシップ｜PagerDuty正規販売代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="PagerDutyは、継続的な改善と革新を推進するために、サービス全体でチームに明確な責任を与えます。このサービスオーナーシップにより、問題が発生した場合に適切なレスポンダーを毎回迅速に引き込むことで、ダウンタイムと顧客への影響を最小限に抑えられます。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/use-cases/service-ownership//"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/use-cases/service-ownership//"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Solution"
        textGreen="PagerDuty活用例：サービスオーナーシップ"
        overview=""
      />

      <div className="serviceOwnershipWrapper">
        <div className="px-[25px] xl:px-0">
          <h2 className="text-2xl leading-8 text-black text-center after:w-0 mb-10">
            明確な責任を持たせ、継続的な改善とイノベーションを推進
          </h2>
          <p className="text-lg leading-8 text-black text-center mb-[100px]">
            現代のビジネスには、完璧なデジタルエクスペリエンスが求められます。モノリシックからマイクロサービスアーキテクチャーへの移行は、競争力を高めるイノベーションを加速させますが、コンポーネントのパフォーマンスに対する明確なオーナーシップと説明責任なしには、サービスのポテンシャルを活かすことはできません。サービスオーナーシップは、常に適切なレスポンダーを迅速に呼び出し、ダウンタイムと問題発生時の顧客への影響を最小限に抑えるために必要です。
          </p>
          <h3 className="text-2xl leading-8 text-black text-center mb-[50px]">
            サービスオーナーシップによるダウンタイムと顧客影響の削減
          </h3>
          <div className="flex gap-y-10 gap-x-[45px] flex-col md:flex-row mb-[50px]">
            <div className="flex-1">
              <svg
                className="mb-[30px] mx-auto"
                width={80}
                height={80}
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_842_24)">
                  <path
                    d="M30.3134 28.1312H19.5508V58.2665H30.3134V28.1312Z"
                    fill="#4EB346"
                  />
                  <path
                    d="M60.448 36.7415H49.6855V58.2665H60.448V36.7415Z"
                    fill="#4EB346"
                  />
                  <path
                    d="M45.3818 21.6745H34.6191V58.2665H45.3818V21.6745Z"
                    fill="#4EB346"
                  />
                  <path
                    d="M69.5903 33.3852L79.1945 31.9527C77.7487 24.8545 74.4186 18.4319 69.7614 13.2511L62.8945 20.1166C66.1427 23.8581 68.4816 28.3847 69.5903 33.3852Z"
                    fill="#4EB346"
                  />
                  <path
                    d="M79.8191 36.2061L70.2163 37.6362C70.2751 38.4067 70.3141 39.1851 70.3141 39.9714C70.3129 42.3482 70.0335 44.6545 69.5176 46.8707L78.7913 49.7479C79.5788 46.6162 80.002 43.3409 80.0007 39.9715C80.0007 38.7006 79.9341 37.4454 79.8191 36.2061Z"
                    fill="#4EB346"
                  />
                  <path
                    d="M61.4344 61.4048C55.9336 66.899 48.3864 70.2828 40.0006 70.284C31.6138 70.2828 24.0652 66.899 18.5656 61.4048C13.0714 55.904 9.68891 48.3569 9.68641 39.9712C9.68891 31.5842 13.0714 24.037 18.5656 18.5362C23.5898 13.5187 30.3247 10.2695 37.8431 9.74186V0.0293579C27.6656 0.571233 18.4638 4.92936 11.7159 11.6865C4.48469 18.9086 -0.00249896 28.9397 1.0441e-06 39.9714C-0.00249896 51.0015 4.48469 61.0314 11.7159 68.2548C18.9378 75.4859 28.9691 79.9733 40.0006 79.9706C51.0309 79.9733 61.0622 75.4859 68.2842 68.2548C72.317 64.2259 75.4945 59.322 77.5161 53.8603L68.245 50.9833C66.7078 54.9195 64.378 58.4586 61.4344 61.4048Z"
                    fill="#4EB346"
                  />
                  <path
                    d="M66.7203 10.2095C60.1434 4.2986 51.5645 0.528291 42.1484 0.029541V9.74063C48.9095 10.2134 55.0333 12.8919 59.8548 17.0763L66.7203 10.2095Z"
                    fill="#4EB346"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_842_24">
                    <rect width={80} height={80} fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p className="text-lg leading-7 text-black text-center">
                サービス／オーナーシップの所在を明確に定義することで、サービス全体のインシデントレスポンスを合理化して、数分以内に適切なレスポンダーを呼び出すことができます。
              </p>
            </div>
            <div className="flex-1">
              <svg
                className="mx-auto mb-[30px]"
                width={80}
                height={80}
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M80 74.5361H0V75.7861H80V74.5361Z" fill="#53B54B" />
                <path
                  d="M17.6074 60.4713V69.9635H29.8926V57.4879C25.9057 58.6988 21.809 59.7096 17.6074 60.4713Z"
                  fill="#53B54B"
                />
                <path
                  d="M1.35742 62.1729V69.9633H13.6426V61.0986C9.62398 61.6749 5.52477 62.0361 1.35742 62.1729Z"
                  fill="#53B54B"
                />
                <path
                  d="M33.8184 56.2061V69.9634H46.1816V51.0156C42.224 52.9858 38.0957 54.7217 33.8184 56.2061Z"
                  fill="#53B54B"
                />
                <path
                  d="M50.0488 48.9844V69.9635H62.4512V40.8668C58.5741 43.8452 54.4287 46.56 50.0488 48.9844Z"
                  fill="#53B54B"
                />
                <path
                  d="M66.2891 37.7613V69.9635H78.7109V24.8364C75.0611 29.4971 70.8936 33.8233 66.2891 37.7613Z"
                  fill="#53B54B"
                />
                <path
                  d="M64.5434 9.01377L68.8647 12.6344C55.1489 29.7486 28.9673 51.4258 0 54.651V58.1105C28.7305 57.0949 59.5483 37.4147 75.5542 18.2399L80 21.963V4.21393L64.5434 9.01377Z"
                  fill="#53B54B"
                />
              </svg>
              <p className="text-lg leading-7 text-black text-center">
                関連するチームメンバー各自がより多くの権限を持つようにすることで、顧客からのフィードバックへの対応を確実に実行し、サービス品質の継続的な向上が可能になります。
              </p>
            </div>
            <div className="flex-1">
              <svg
                className="mx-auto mb-[30px]"
                width={80}
                height={80}
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_842_33)">
                  <path
                    d="M80 0H0V6.25H2.5V52.5H77.5V6.25H80V0ZM72.5 47.5H7.5V6.25H72.5V47.5Z"
                    fill="#53B54B"
                  />
                  <path d="M42.5 55H37.5V80H42.5V55Z" fill="#53B54B" />
                  <path
                    d="M17.6992 80H22.6992L33.1533 55H28.1533L17.6992 80Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M46.8477 55L57.3017 80H62.3017L51.8477 55H46.8477Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M29.5846 36.3685C35.2205 36.3674 39.7921 31.7956 39.7934 26.1597C39.7946 23.6927 38.9132 21.4306 37.4679 19.6694L37.4643 19.6656C35.5893 17.4038 32.7499 15.9522 29.5846 15.9522C29.4502 15.9522 29.2952 15.9522 29.1255 15.9631C29.123 15.9631 29.1218 15.9631 29.1182 15.9631C29.1145 15.9631 29.1134 15.9631 29.1073 15.9631V15.9644C23.6946 16.2183 19.3782 20.6824 19.377 26.1597C19.3784 31.7956 23.9499 36.3672 29.5846 36.3685ZM29.6323 25.9558V17.8113C32.0323 17.8235 34.1904 18.8622 35.7138 20.5017L29.6323 25.9558Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M60.625 16.5772H45.625V20.3272H60.625V16.5772Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M60.625 24.0772H45.625V27.8272H60.625V24.0772Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M55.625 31.5772H45.625V35.3272H55.625V31.5772Z"
                    fill="#53B54B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_842_33">
                    <rect width={80} height={80} fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p className="text-lg leading-7 text-black text-center">
                組織構造ではなくサービスに焦点を当てた柔軟なオーナーシップモデルにより、優先順位、役割、構造を容易に変更することができ、過去の測定基準とデータも維持できます。
              </p>
            </div>
          </div>
          <img
            src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/services_diagram_define_structure_automate_through_services_1_ed7a5d989e.png?updated_at=2022-12-14T10:27:44.372Z"
            width="1080px"
            height="607px"
            loading="lazy"
            className="mb-[100px]"
            alt="services_diagram_define_structure_automate_through_services"
          />
          <div className="flex flex-col-reverse md:flex-row gap-y-10 gap-x-[50px] mb-[60px]">
            <div className="flex-1">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/IMAGE_8250363641.svg"
                width="500px"
                height="295px"
                loading="lazy"
                className="bg-cover w-full"
                alt="オーナーシップとアカウンタビリティーを定義する"
              />
            </div>
            <div className="flex-1">
              <h3 className="text-2xl leading-8 text-black mb-[30px]">
                オーナーシップとアカウンタビリティーを定義する
              </h3>
              <p className="text-lg leading-8 text-black">
                技術サービスをビジネスへの影響に関連付けることで、サービス階層全体を定義します。サービスベースのアーキテクチャーへの移行に伴い、PagerDutyのサービスディレクトリーを利用したプラットフォームでは、サービスの内容、所有者、依存関係を全て明確にすることができます。AI機能により、どのコンポーネントに問題が発生しているのか、またそれに関連する全ての依存関係を迅速に特定し、インシデントを迅速に解決して顧客への影響を軽減することができます。
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-y-10 gap-x-[50px] mb-[60px]">
            <div className="flex-1">
              <h3 className="text-2xl leading-8 text-black mb-[30px]">
                インシデント対応の効率化
              </h3>
              <p className="text-lg leading-8 text-black">
                サービスは壊れるものです。そのインシデントをどのように処理するかによって、引き続き顧客であり続けてもらえるか、競合他社に乗り換えられてしまうかが決まります。PagerDutyでは、適切なチームを数秒で動員することができます。リアルタイムでビジネス関係者を巻き込み、組織全体で協力してプロアクティブに対応し、顧客とのロイヤリティーと信頼を築きましょう。
              </p>
            </div>
            <div className="flex-1">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/incident_response_real_time_business_response_after_pagerduty_diagram_1_2_1bd31462dd.png"
                width="500px"
                height="289px"
                loading="lazy"
                className="bg-cover"
              />
            </div>
          </div>
          <div className="flex flex-col-reverse md:flex-row gap-y-10 gap-x-[50px] mb-[100px]">
            <div className="flex-1">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Service_Graph_03f25bacb5.png"
                width="500px"
                height="295px"
                loading="lazy"
                className="object-cover"
                alt="顧客に影響を与える問題を未然に防ぐ"
              />
            </div>
            <div className="flex-1">
              <h3 className="text-2xl leading-8 text-black mb-[30px]">
                顧客に影響を与える問題を未然に防ぐ
              </h3>
              <p className="text-lg leading-8 text-black">
                サービスオーナーシップの導入は、ダウンタイムに迅速に対応し卓越したカスタマーエクスペリエンスを提供すること標準となる、熟練のデジタル運用に向けた重要なステップです。サービスオーナーシップを明確に定義することで、チームは行動力を身につけ、継続的な改善に取り組むことができるようになります。
              </p>
            </div>
          </div>
          <h3 className="text-center font-bold text-black text-2xl leading-8 mb-10">
            現在お使いのツールとの連携
          </h3>
          <p className="text-center text-lg leading-8 text-black mb-[30px]">
            今お使いのツールで動作するため、プロセスを変更する必要はありません。
            <br />
            <br />
            650以上のネイティブな統合機能と、拡張可能なPagerDuty
            APIを使用してワークフローを構築・カスタマイズする機能により、あらゆるツールからデータを統合し、ITインフラへのインサイトを提供します。強化されたEvent
            API
            v2は、全ての受信イベントを共通のフィールドに自動的に正規化します。
            <br />
            <br />
            双方向の拡張機能によりツール切り替えの手間はなく、インラインで問題を修正し、任意のChatOpsツールで運用関連のタスクを自動化可能。現在お使いのツールとよくなじみます。
          </p>
          <img
            src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/integration_diagram_4_ae3b5aa54e.svg"
            width="600px"
            height="333px"
            loading="lazy"
            className="flex-1 mx-auto"
            alt="Group"
          />
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedUseCasesServiceOwnershipIndex;
